import React, {useState} from "react";
import PropTypes from "prop-types";
import {NavLink, useNavigate} from "react-router-dom";

export default function LoginForm(props) {

    const [emailText, setEmail] = useState("");
    const [passwordText, setPassword] = useState("");
    const navigate = useNavigate();

    const handleMailChange = (event) => {
        setEmail(event.target.value);
        let txt = emailText;
        console.log(txt);
    }

    const handlePassChange = (event) => {
        setPassword(event.target.value);
        let txt = passwordText;
        console.log(txt);
    }

    const btnSignUp = async (e) => {
        e.preventDefault();
        let userData = {
            userName: emailText,
            passWord: passwordText
        }
        const response = await fetch("http://localhost:5000/api/auth_enc", {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(userData)
        });
        const json = await response.json();
        console.log(json);
        if (json.success) {
            alert("Created");
        } else {
            alert("Failed")
        }
    }

    const btnLogIn = async (e) => {
        e.preventDefault();
        let userData = {
            userName: emailText,
            passWord: passwordText
        }
        try {
            const response = await fetch("http://localhost:5000/api/auth_enc/verify_user", {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(userData)
            });
            const json = await response.json();
            console.log(json);
            if (json.success) {
                navigate("/about");
                alert("Successful");
            } else {
                alert("Failed")
            }
        } catch (e) {
            alert("Failed")
        }
    }

    return (
        <>
            <div className="container mb-3">
                <label htmlFor="exampleInputEmail1" className="form-label">Email address</label>
                <input type="text" className="form-control" value={emailText} onChange={handleMailChange}
                       id="exampleInputEmail1" aria-describedby="emailHelp"/>
                <div id="emailHelp" className="form-text">We'll never share your email with anyone else.</div>
            </div>
            <div className="container mb-3">
                <label htmlFor="exampleInputPassword1" className="form-label">Password</label>
                <input type="text" className="form-control" value={passwordText} onChange={handlePassChange}
                       id="exampleInputPassword1"/>
            </div>
            <div className="container mb-3">
                <button className="btn btn-primary mx-3" onClick={btnSignUp}>Sign Up</button>
                <NavLink className="btn btn-primary mx-3" onClick={btnLogIn} to="/about">Log In</NavLink>
                <button className="btn btn-primary mx-3" onClick={props.enableDark}>{props.btnText}</button>
            </div>
        </>
    )
};