import React, {useState} from "react";
export default function ImageSelect () {

    const [image, setImage] = useState();

    const submitImage = (event) => {
        event.preventDefault();

        const formData = new FormData();
        formData.append("image", image);
    }
    const handleImage = (event) => {
        console.log(event.target.files[0]);
        setImage(event.target.files[0]);
    }
    return (
        <>
            <form>
            <div className="container mb-3">
                <input type="file" className="form-control" onChange={handleImage} id="exampleInputFile" accept="image/jpeg"/>
                <button className="btn btn-primary my-2" onClick={submitImage}>Submit Image</button>
            </div>
            </form>
        </>
    )
}