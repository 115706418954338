import logo from './logo.svg' ;
import './App.css';
import NavBar from "./components/NavBar";
import LoginForm from "./components/LoginForm";
import {Fragment, useState} from "react";
import ImageSelect from "./components/ImageSelect";

import {BrowserRouter as Router, Routes, Route, Link} from "react-router-dom";
import About from "./components/About";
import Home from "./components/Home";

import { initializeApp } from "firebase/app";
import { getPerformance } from "firebase/performance";

function App() {
    const [mode, setMode] = useState("light")
    const [btnText, setBtnText] = useState("Enable dark mode")
    const enableDark = () => {
        if (mode === "light") {
            setMode("dark");
            setBtnText("Enable light mode");
        } else {
            setMode("light")
            setBtnText("Enable dark mode");
        }
    }


// Your web app's Firebase configuration
    const firebaseConfig = {
        apiKey: "AIzaSyClBY8Wpa8M7WzlnbggJfSmrhbAwbwzxTs",
        authDomain: "react-hosting-test-4cafa.firebaseapp.com",
        projectId: "react-hosting-test-4cafa",
        storageBucket: "react-hosting-test-4cafa.appspot.com",
        messagingSenderId: "369701405133",
        appId: "1:369701405133:web:01dd2f65d33f63a2198484"
    };

// Initialize Firebase
    const app = initializeApp(firebaseConfig);
    const perf = getPerformance(app);

    return (
        <>
            <Router>
                <NavBar titleName="My 1st App" mode={mode}/>
                <div className="container">
                    <Routes>
                        <Route exact path="/" element={[<LoginForm enableDark={enableDark} btnText={btnText}/>, <ImageSelect/>]}/>
                        <Route path="/home" element={<Home/>}/>
                        <Route path="/about" element={<About/>}/>
                    </Routes>
                </div>
            </Router>
        </>
    );
}

export default App;
